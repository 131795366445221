/* eslint-disable */
/* tslint:disable */
import PropTypes from 'prop-types';
import React from 'react';
const Forward10Seconds = ({size, ...props}) => (
  <svg viewBox="0 0 16 16" fill="currentColor" width={ size || "16" } height={ size || "16" } {...props}>
    <path d="M9.32001 2.97965L8 1.33301M12.7266 5.20015C13.4666 6.18682 13.9266 7.40682 13.9266 8.74015 13.9266 12.0135 11.2733 14.6668 7.99992 14.6668 4.72658 14.6668 2.07324 12.0135 2.07324 8.74015 2.07324 5.46682 4.72658 2.81348 7.99992 2.81348 8.45325 2.81348 8.89326 2.87351 9.31992 2.97351"
    />
    <path d="M6.36035 10.6127V7.05273L5.36035 8.16606M9.33382 7.05273C10.0672 7.05273 10.6672 7.65273 10.6672 8.38607V9.28605C10.6672 10.0194 10.0672 10.6194 9.33382 10.6194 8.60049 10.6194 8.00049 10.0194 8.00049 9.28605V8.38607C8.00049 7.64607 8.60049 7.05273 9.33382 7.05273z"
    />
  </svg>
);
Forward10Seconds.displayName = 'Forward10Seconds';
Forward10Seconds.propTypes = {
  size: PropTypes.string
}
export default Forward10Seconds;
/* tslint:enable */
/* eslint-enable */
