/* eslint-disable */
/* tslint:disable */
import PropTypes from 'prop-types';
import React from 'react';
const Globe = ({size, ...props}) => (
  <svg viewBox="0 0 205.229 205.229" fill="currentColor" width={ size || "1em" } height={ size || "1em" } {...props}>
    <path d="M102.618,205.229c-56.585,0-102.616-46.031-102.616-102.616C0.002,46.031,46.033,0,102.618,0 C159.2,0,205.227,46.031,205.227,102.613C205.227,159.198,159.2,205.229,102.618,205.229z M102.618,8.618 c-51.829,0-94.002,42.166-94.002,93.995s42.17,93.995,94.002,93.995c51.825,0,93.988-42.162,93.988-93.995 C196.606,50.784,154.444,8.618,102.618,8.618z"
      fill="currentColor" />
    <path fill="currentColor" d="M5.746 98.304H199.48000000000002V106.922H5.746zM104.941 62.111c-48.644 0-84.94-10.704-87.199-11.388l2.494-8.253c.816.247 82.657 24.336 164.38-.004l2.452 8.26C158.405 59.266 130.021 62.111 104.941 62.111zM20.416 160.572l-2.459-8.26c84.271-25.081 165.898-1.027 169.333 0l-2.494 8.256C183.976 160.318 102.142 136.24 20.416 160.572z"
    />
    <path d="M69.399 196.168C26.933 96.747 63.584 8.604 63.959 7.727l7.927 3.378c-.365.845-35.534 85.756 5.44 181.677L69.399 196.168zM135.168 196.168l-7.927-3.382c40.971-95.92 5.801-180.832 5.436-181.677l7.927-3.378C140.973 8.604 177.627 96.747 135.168 196.168zM98.306 5.744H106.92V203.046H98.306z"
      fill="currentColor" />
  </svg>
);
Globe.displayName = 'Globe';
Globe.propTypes = {
  size: PropTypes.string
}
export default Globe;
/* tslint:enable */
/* eslint-enable */
