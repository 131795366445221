import * as SliderPrimitive from '@radix-ui/react-slider';

export const Slider = ({ className, thumbClassName, trackClassName, ...props }: any) => (
    <SliderPrimitive.Root
        className={`relative flex touch-none select-none items-center ${className}`}
        {...props}
    >
        <SliderPrimitive.Track
            className={`relative transform-height duration-150 ease-in-out h-2 hover:h-3 group w-full grow overflow-hidden rounded-[4px] outline-none ${trackClassName}`}
        >
            <SliderPrimitive.Range className="absolute h-full bg-primary focus:bg-primary" />
        </SliderPrimitive.Track>
        <SliderPrimitive.Thumb
            className={`block h-5 w-5 group-hover:h-6 group-hover:w-6 rounded-[100%] border-2 border-primary focus:border-primary bg-[rgb(184,194,214)] disabled:pointer-events-none disabled:opacity-50 
                    ${thumbClassName}`}
        />
    </SliderPrimitive.Root>
);
