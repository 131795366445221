import React, { useState, useRef, useEffect } from 'react';
import './dropdown.scss';
import { ChevronDown } from 'assets/iconComponents';
import { getResponsiveIconSize } from 'utils/responsiveIconSize';

interface DropdownProps {
    children: React.ReactNode;
    title?: string;
    dropdownBtn?: React.ReactNode;
    dropdownClass? : string,
    closeOnClickInside?: boolean;
}

export const Dropdown: React.FC<DropdownProps> = ({ children, title, dropdownBtn, dropdownClass, closeOnClickInside = true }) => {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef<HTMLDivElement>(null);

    const handleClickOutside = (event: MouseEvent) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div ref={dropdownRef} className="relative">
            {title ? (
                <button
                    onClick={() => setIsOpen(!isOpen)}
                    className="flex items-center rounded-8  justify-between dropdown-button"
                >
                    {title}
                    <ChevronDown size={getResponsiveIconSize(16)} />
                </button>
            ) : (
                <div onClick={() => setIsOpen(!isOpen)}>{dropdownBtn}</div>
            )}
            {isOpen && (
                <div onClick={() => closeOnClickInside && setIsOpen(false)} className={`absolute dropdown-list z-10 w-full p-2 mt-2 bg-white rounded-8 shadow-lg ${dropdownClass} `}>
                    {children}
                </div>
            )}
        </div>
    );
};
