/* eslint-disable */
/* tslint:disable */
import PropTypes from 'prop-types';
import React from 'react';
const MutedVolume = ({size, ...props}) => (
  <svg viewBox="0 0 16 16" fill="currentColor" width={ size || "16" } height={ size || "16" } {...props}>
    <path d="M10 5.62372V5.02566C10 3.16919 8.72615 2.459 7.17538 3.4433L5.37846 4.58335C5.18154 4.70171 4.95385 4.77024 4.72615 4.77024H3.84615C2.61538 4.77024 2 5.39322 2 6.63917V9.13107C2 10.377 2.61538 11 3.84615 11H5.07692"
    />
    <path opacity=".4" d="M7 12.588C8.64706 13.5053 10 12.8376 10 11.1133V9M12 6C12 7.30699 12 8.83283 12 10M13.6087 5C14.2866 7.28318 14.0837 9.95234 13 12" />
    <path d="M14 2L2 14" />
  </svg>
);
MutedVolume.displayName = 'MutedVolume';
MutedVolume.propTypes = {
  size: PropTypes.string
}
export default MutedVolume;
/* tslint:enable */
/* eslint-enable */
