import { Dispatch, KeyboardEventHandler, SetStateAction, useEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import screenfull from 'screenfull';
import { PlayerController } from './controllers';
import { Replay } from 'assets/iconComponents';
import { LoadingAnimation } from 'components/loadingAnimation';

interface VideoPlayerProps {
    played: number;
    duration: number;
    isPlaying: boolean;
    setPlayed: Dispatch<SetStateAction<number>>;
    setDuration: Dispatch<SetStateAction<number>>;
    setIsPlaying: Dispatch<SetStateAction<boolean>>;
    isVideoReady: boolean;
    setIsVideoReady: Dispatch<SetStateAction<boolean>>;
    url: string;
}

export const VideoPlayer = ({
    played,
    setPlayed,
    duration,
    setDuration,
    isPlaying,
    setIsPlaying,
    isVideoReady,
    setIsVideoReady,
    url,
}: VideoPlayerProps) => {
    const [speed, setSpeed] = useState<number>(1);
    const [volume, setVolume] = useState<number>(1);
    const [loaded, setLoaded] = useState<number>(0);
    const [isEnd, setIsEnd] = useState<boolean>(false);
    const [isError, setIsError] = useState(false);

    const player = useRef<ReactPlayer | null>(null);
    const playerContainer = useRef(null);

    const loading = loaded <= played;

    const handlePlayPause = () => setIsPlaying((prev) => !prev);

    const handleForwardMove = () =>
        played < 1 && setPlayed((prev) => Math.min(prev + 10 / duration, 1));

    const handleBackwardMove = () =>
        played > 0 && setPlayed((prev) => Math.max(prev - 10 / duration, 0));

    const handleVolumeChange = (value: number[]) => setVolume(+value[0]);

    const handleVolumeUp = () => setVolume((prev) => Math.min(prev + 0.1, 1));

    const handleVolumeDown = () => setVolume((prev) => Math.max(prev - 0.1, 0));

    const handleMute = () => setVolume(0);

    const handleUnMute = () => setVolume(1);

    const handleSpeed = (speedOption: number) => setSpeed(speedOption);

    const handleProgressChange = (value: number[]) => setPlayed(+value[0] / duration);

    const handleProgress = (state: { played: number; loaded: number }) => {
        setLoaded(state.loaded);
        setPlayed(state.played);
    };

    const handleEnd = () => {
        setIsEnd(true);
        setPlayed(0);
        setLoaded(0);
        setIsPlaying(false);
    };

    const handleReply = () => {
        setIsEnd(false);
        setIsPlaying(true);
    };

    const handleExpand = () => {
        if (screenfull.isFullscreen) {
            screenfull.exit();
        } else if (playerContainer?.current) {
            screenfull.isEnabled && screenfull.request(playerContainer?.current);
        }
    };

    const handleKeyDown: KeyboardEventHandler<HTMLDivElement> = (event) => {
        if (event.key === 'ArrowLeft') {
            event.preventDefault();
            handleBackwardMove();
        } else if (event.key === 'ArrowRight') {
            event.preventDefault();
            handleForwardMove();
        } else if (event.key === ' ') {
            event.preventDefault();
            handlePlayPause();
        }
    };

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key === 'ArrowUp') {
                event.preventDefault();
                handleVolumeUp();
            } else if (event.key === 'ArrowDown') {
                event.preventDefault();
                handleVolumeDown();
            }
        };

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    useEffect(() => {
        if (played >= 1) {
            setPlayed(1);
            handleEnd();
        }
        player?.current?.seekTo(played);
    }, [played]);

    return (
        <>
            <div
                ref={playerContainer}
                className={`relative rounded-[7px] bg-black group outline-none h-fit w-[50rem] rounder-[0.9rem]`}
                tabIndex={0}
                onKeyDown={handleKeyDown}
            >
                <ReactPlayer
                    onReady={() => setIsVideoReady(true)}
                    onError={() => setIsError(true)}
                    ref={player}
                    onDuration={(number) => setDuration(number)}
                    width={'100%'}
                    height={'100%'}
                    url={url}
                    playing={isPlaying}
                    muted={!volume}
                    playbackRate={speed}
                    volume={volume}
                    onProgress={handleProgress}
                    played={played}
                    loaded={loaded}
                    loop={false}
                    style={{ outline: 'none' }}
                    onEnded={handleEnd}
                />

                {isError ? (
                    <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white quicksand text-[12px]">
                        Error Display Video
                    </div>
                ) : loading || !isVideoReady ? (
                    <LoadingAnimation className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[5px] h-[50px]" />
                ) : isEnd ? (
                    <Replay
                        onClick={handleReply}
                        className="cursor-pointer absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white fill-none"
                    />
                ) : (
                    <>
                        <div
                            className="absolute top-0 left-0 w-full h-full bg-transparent"
                            onClick={handlePlayPause}
                        />

                        <PlayerController
                            handlePlayPause={handlePlayPause}
                            duration={duration}
                            handleProgressChange={handleProgressChange}
                            speed={speed}
                            handleSpeed={handleSpeed}
                            volume={volume}
                            handleVolumeChange={handleVolumeChange}
                            handleMute={handleMute}
                            handleUnMute={handleUnMute}
                            handleBackwardMove={handleBackwardMove}
                            handleForwardMove={handleForwardMove}
                            isPlaying={isPlaying}
                            iconsClassName="stroke-white text-white"
                            currentTime={played * duration}
                            progressClassName="bg-[#EBEBEB]"
                            volumeClassName="bg-[#EBEBEB]"
                            handleReply={handleReply}
                            isVideo={true}
                            handleExpand={handleExpand}
                        />
                    </>
                )}
            </div>
        </>
    );
};
