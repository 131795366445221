/* eslint-disable */
/* tslint:disable */
import PropTypes from 'prop-types';
import React from 'react';
const Play = ({size, ...props}) => (
  <svg viewBox="0 0 16 16" fill="currentColor" width={ size || "16" } height={ size || "16" } {...props}>
    <path d="M2.6665 8.00026V5.62692C2.6665 2.68026 4.75317 1.47359 7.3065 2.94692L9.3665 4.13359L11.4265 5.32026C13.9798 6.79359 13.9798 9.20692 11.4265 10.6803L9.3665 11.8669L7.3065 13.0536C4.75317 14.5269 2.6665 13.3203 2.6665 10.3736V8.00026Z"
    />
  </svg>
);
Play.displayName = 'Play';
Play.propTypes = {
  size: PropTypes.string
}
export default Play;
/* tslint:enable */
/* eslint-enable */
