import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import './speechItem.scss';
import Player from 'video.js/dist/types/player';

type TimeSlot = {
    start: number;
    end: number;
};

interface SpeechItemProps {
    duration: number | undefined;
    speech: {
        firstName: string;
        lastName: string;
        timeSlots: TimeSlot[];
    };
    avatarBackgroundColor: string;
    isVideo: boolean;
    setPlayed: Dispatch<SetStateAction<number>>;
    setIsVideoPlaying: Dispatch<SetStateAction<boolean>>;
    wavesurfer: any;
    isVideoReady: boolean;
    isAudioReady: boolean;
    setIsAudioPlaying: Dispatch<SetStateAction<boolean>>;
}

export const SpeechItem = ({
    duration,
    speech,
    avatarBackgroundColor,
    isVideo,
    setPlayed,
    setIsVideoPlaying,
    wavesurfer,
    isVideoReady,
    isAudioReady,
    setIsAudioPlaying,
}: SpeechItemProps) => {
    const getTimeSlotWidth = (slot: TimeSlot): number => {
        const timeDuration = duration || 0;
        return ((slot.end - slot.start) / timeDuration) * 100;
    };

    const getTimeSlotPosition = (slot: TimeSlot): number => {
        const timeDuration = duration || 0;

        return (slot.start / timeDuration) * 100;
    };
    const getSpeechPercentage = (): number => {
        const timeDuration = duration || 0;
        if (timeDuration <= 0) return 0;

        const percentage = (
            (speech.timeSlots.reduce((acc, slot) => {
                return acc + (Math.ceil(slot.end) - Math.ceil(slot.start));
            }, 0) /
                timeDuration) *
            100
        ).toFixed(2);

        return Number(percentage);
    };

    const getUserInitials = (): string => {
        return speech.firstName[0] + (speech.lastName?.[0] || '');
    };

    const handleTimeSlotClick = (slot: TimeSlot): void => {
        if (isVideo && isVideoReady) {
            duration && setPlayed(slot.start / duration);
            setIsVideoPlaying(true);
        } else if (isAudioReady && !isVideo) {
            wavesurfer?.setTime(slot.start);
            wavesurfer?.play();
            setIsAudioPlaying(true);
        }
    };

    return (
        <div className="timeline-item bg-white rounded-8 px-4">
            <div className="timeline-header">
                <div className="user-info">
                    <span
                        style={{ backgroundColor: avatarBackgroundColor }}
                        className="user-initials"
                    >
                        <span>{getUserInitials()}</span>
                    </span>
                    <span className="user-name">{`${speech.firstName} ${
                        speech.lastName || ''
                    }`}</span>
                </div>
                <div className="speech-percentage">
                    <span className="speech-percentage-value">{getSpeechPercentage()}%</span>
                </div>
            </div>
            <div className="timeline-container">
                <span className="timeline-placeholder" />

                {speech.timeSlots.map((slot: TimeSlot) => {
                    return (
                        <span
                            key={slot.start}
                            onClick={() => handleTimeSlotClick(slot)}
                            style={{
                                width: `${getTimeSlotWidth(slot)}%`,
                                left: `${getTimeSlotPosition(slot)}%`,
                            }}
                            className="timeline-slot"
                        />
                    );
                })}
            </div>
        </div>
    );
};
