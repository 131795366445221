/* eslint-disable */
/* tslint:disable */
import PropTypes from 'prop-types';
import React from 'react';
const RotateLeft = ({size, ...props}) => (
  <svg viewBox="0 0 16 16" fill="currentColor" width={ size || "16" } height={ size || "16" } {...props}>
    <path opacity=".4" d="M6.0733 3.38628C6.6533 3.21294 7.2933 3.09961 7.99997 3.09961C11.1933 3.09961 13.78 5.68628 13.78 8.87961C13.78 12.0729 11.1933 14.6596 7.99997 14.6596C4.80664 14.6596 2.21997 12.0729 2.21997 8.87961C2.21997 7.69294 2.57997 6.58628 3.1933 5.66628"
      stroke="currentColor" strokeWidth="1.12" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M5.24658 3.54634L7.17325 1.33301M5.24658 3.54688L7.49325 5.18687" stroke="currentColor" strokeWidth="1.12" strokeLinecap="round" strokeLinejoin="round"
    />
  </svg>
);
RotateLeft.displayName = 'RotateLeft';
RotateLeft.propTypes = {
  size: PropTypes.string
}
export default RotateLeft;
/* tslint:enable */
/* eslint-enable */
