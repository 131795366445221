/* eslint-disable */
/* tslint:disable */
import PropTypes from 'prop-types';
import React from 'react';
const Check = ({size, ...props}) => (
  <svg viewBox="0 0 9 7" fill="currentColor" width={ size || "9" } height={ size || "7" } {...props}>
    <path d="M1.48956 3.49995L3.49415 5.50453L7.5104 1.49536" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
Check.displayName = 'Check';
Check.propTypes = {
  size: PropTypes.string
}
export default Check;
/* tslint:enable */
/* eslint-enable */
