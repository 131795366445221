/* eslint-disable */
/* tslint:disable */
import PropTypes from 'prop-types';
import React from 'react';
const Backward10Seconds = ({size, ...props}) => (
  <svg viewBox="0 0 16 16" fill="currentColor" width={ size || "16" } height={ size || "16" } {...props}>
    <path d="M6.35986 10.6137V7.05371L5.35986 8.16703M6.68018 2.97965L8.00018 1.33301" />
    <path d="M3.27325 5.20015C2.53325 6.18682 2.07324 7.40682 2.07324 8.74015C2.07324 12.0135 4.72658 14.6668 7.99992 14.6668C11.2733 14.6668 13.9266 12.0135 13.9266 8.74015C13.9266 5.46682 11.2733 2.81348 7.99992 2.81348C7.54658 2.81348 7.10658 2.87351 6.67991 2.97351"
    />
    <path d="M9.33333 7.05371C10.0667 7.05371 10.6667 7.65371 10.6667 8.38704V9.28703C10.6667 10.0204 10.0667 10.6204 9.33333 10.6204C8.6 10.6204 8 10.0204 8 9.28703V8.38704C8 7.64704 8.6 7.05371 9.33333 7.05371Z"
    />
  </svg>
);
Backward10Seconds.displayName = 'Backward10Seconds';
Backward10Seconds.propTypes = {
  size: PropTypes.string
}
export default Backward10Seconds;
/* tslint:enable */
/* eslint-enable */
