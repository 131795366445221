/* eslint-disable */
/* tslint:disable */
import PropTypes from 'prop-types';
import React from 'react';
const EjazLogo = ({size, ...props}) => (
  <svg viewBox="0 0 22 23" fill="currentColor" width={ size || "22" } height={ size || "23" } {...props}>
    <path d="M14.1527 10.5872C14.6277 10.5872 15.0129 10.2021 15.0129 9.72702 15.0129 9.25194 14.6277 8.86682 14.1527 8.86682 13.6776 8.86682 13.2925 9.25194 13.2925 9.72702 13.2925 10.2021 13.6776 10.5872 14.1527 10.5872zM10.5401 10.5872C11.0152 10.5872 11.4003 10.2021 11.4003 9.72702 11.4003 9.25194 11.0152 8.86682 10.5401 8.86682 10.0651 8.86682 9.67993 9.25194 9.67993 9.72702 9.67993 10.2021 10.0651 10.5872 10.5401 10.5872zM6.92758 10.5872C7.40265 10.5872 7.78777 10.2021 7.78777 9.72702 7.78777 9.25194 7.40265 8.86682 6.92758 8.86682 6.45251 8.86682 6.06738 9.25194 6.06738 9.72702 6.06738 10.2021 6.45251 10.5872 6.92758 10.5872z"
      fill="currentColor" />
    <path d="M3.34928 11.8535V.0930176H1.24849C.735567.0930176.31958.509004.31958 1.02193V11.8584C.31958 14.2353 2.24122 16.1655 4.61933 16.1754L7.0588 16.1839V13.1383H4.63651C3.92602 13.1383 3.35051 12.5628 3.35051 11.8523L3.34928 11.8535zM14.9454 22.0691C14.6779 22.0691 14.4264 21.966 14.2361 21.7783 13.8398 21.3868 13.8349 20.7463 14.2263 20.3499L16.9296 17.6111C17.1174 17.4209 17.3775 17.3117 17.645 17.3104 17.9187 17.3104 18.1715 17.416 18.3629 17.6062L21.1079 20.345C21.2993 20.5352 21.4036 20.7892 21.4049 21.0592 21.4049 21.3292 21.3005 21.5832 21.1091 21.7734 20.9177 21.9648 20.6637 22.0703 20.3937 22.0703 20.1238 22.0703 19.871 21.966 19.6796 21.7746L17.6536 19.7536 15.6645 21.7697C15.4731 21.9636 15.2178 22.0703 14.9454 22.0703V22.0691z"
      fill="currentColor" />
    <path d="M13.2312 3.27734H9.54864L7.0564 0.0930176V6.30827H13.4361C16.4928 6.30827 18.9703 8.78578 18.9703 11.8425C18.9703 12.553 18.3948 13.1285 17.6843 13.1285H9.75479L7.05518 16.1852L17.8622 16.1766C20.1459 16.1815 22 14.331 22 12.0462C22 7.20282 18.0733 3.27734 13.2312 3.27734Z"
      fill="currentColor" />
  </svg>
);
EjazLogo.displayName = 'EjazLogo';
EjazLogo.propTypes = {
  size: PropTypes.string
}
export default EjazLogo;
/* tslint:enable */
/* eslint-enable */
