/* eslint-disable */
/* tslint:disable */
import PropTypes from 'prop-types';
import React from 'react';
const Copy = ({size, ...props}) => (
  <svg viewBox="0 0 15 14" fill="currentColor" width={ size || "15" } height={ size || "14" } {...props}>
    <path d="M10.4147 7.81671V9.56671C10.4147 11.9 9.4814 12.8334 7.14807 12.8334H4.9314C2.59807 12.8334 1.66473 11.9 1.66473 9.56671V7.35004C1.66473 5.01671 2.59807 4.08337 4.9314 4.08337H6.6814"
      stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M10.4147 7.81671H8.54806C7.14806 7.81671 6.6814 7.35004 6.6814 5.95004V4.08337L10.4147 7.81671zM7.26471 1.16663H9.59804M4.58136 2.91663C4.58136 1.94829 5.36303 1.16663 6.33136 1.16663H7.85969M13.3314 4.66663V8.27746C13.3314 9.18163 12.5964 9.91663 11.6922 9.91663M13.3314 4.66663H11.5814C10.2689 4.66663 9.83136 4.22913 9.83136 2.91663V1.16663L13.3314 4.66663z"
      stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
Copy.displayName = 'Copy';
Copy.propTypes = {
  size: PropTypes.string
}
export default Copy;
/* tslint:enable */
/* eslint-enable */
