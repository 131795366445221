/* eslint-disable */
/* tslint:disable */
import PropTypes from 'prop-types';
import React from 'react';
const Volume = ({size, ...props}) => (
  <svg viewBox="0 0 16 16" fill="currentColor" width={ size || "16" } height={ size || "16" } {...props}>
    <path d="M1.3335 6.66685V9.33352C1.3335 10.6669 2.00016 11.3335 3.3335 11.3335H4.28683C4.5335 11.3335 4.78016 11.4069 4.9935 11.5335L6.94016 12.7535C8.62016 13.8069 10.0002 13.0402 10.0002 11.0602V4.94019C10.0002 2.95352 8.62016 2.19352 6.94016 3.24685L4.9935 4.46685C4.78016 4.59352 4.5335 4.66685 4.28683 4.66685H3.3335C2.00016 4.66685 1.3335 5.33352 1.3335 6.66685zM12.0005 5.33301C13.1872 6.91301 13.1872 9.08634 12.0005 10.6663M13.2197 3.66699C15.1464 6.23366 15.1464 9.76699 13.2197 12.3337"
    />
  </svg>
);
Volume.displayName = 'Volume';
Volume.propTypes = {
  size: PropTypes.string
}
export default Volume;
/* tslint:enable */
/* eslint-enable */
