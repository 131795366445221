/* eslint-disable */
/* tslint:disable */
import PropTypes from 'prop-types';
import React from 'react';
const Refresh = ({size, ...props}) => (
  <svg viewBox="0 0 18 20" fill="currentColor" width={ size || "18" } height={ size || "20" } {...props}>
    <path d="M17.0055 6.81742C16.8744 6.56109 16.2353 5.97378 15.1572 6.50703C14.0793 7.04034 14.6003 8.21154 14.6003 8.21154C15.0021 9.0399 15.2282 9.96979 15.2282 10.9537C15.2282 14.4112 12.4396 17.2143 8.99963 17.2143C5.56008 17.2143 2.77144 14.4112 2.77144 10.9537C2.77144 7.71754 5.21408 5.05553 8.34636 4.72745V6.34987C8.34636 6.34987 8.34196 6.4466 8.44218 6.50788C8.54208 6.56915 8.64231 6.50788 8.64231 6.50788L13.8094 3.4476C13.8094 3.4476 13.9253 3.38638 13.9253 3.26657C13.9253 3.16788 13.8094 3.0977 13.8094 3.0977L8.6595 0.0547606C8.6595 0.0547606 8.54208 -0.0298035 8.43777 0.0112404C8.33357 0.0519682 8.34636 0.194647 8.34636 0.194647V1.93298C3.68135 2.26976 0 6.17768 0 10.9537C0 15.9498 4.02945 20 8.99963 20C13.9702 20 18 15.9498 18 10.9537C18.0001 9.46272 17.6408 8.0569 17.0055 6.81742Z"
      fill="currentColor" />
  </svg>
);
Refresh.displayName = 'Refresh';
Refresh.propTypes = {
  size: PropTypes.string
}
export default Refresh;
/* tslint:enable */
/* eslint-enable */
