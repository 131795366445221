import { useState } from 'react';
import { Slider } from '../../slider';
import { HamsaTooltip } from '../../hamsaTooltip';
import { formatTime } from 'utils/formateTime';

interface ProgressBarProps {
    handleProgressChange: (value: number[]) => void;
    currentTime: number;
    duration: number;
    progressClassName: string;
}
export const ProgressBar = ({
    handleProgressChange,
    currentTime,
    duration,
    progressClassName,
}: ProgressBarProps) => {
    const [tooltipPosition, setTooltipPosition] = useState<{ left: number }>({ left: 0 });
    const [hoveredTime, setHoveredTime] = useState<number | null>(null);

    const handleMouseMove = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        const rect = e.currentTarget.getBoundingClientRect();
        const offsetX = e.clientX - rect.left;
        const percentage = offsetX / rect.width;
        const time = percentage * duration;
        setHoveredTime(time);

        const tooltipLeft = e.clientX - rect.left;
        setTooltipPosition({ left: tooltipLeft });
    };

    const handleMouseLeave = () => setHoveredTime(null);

    return (
        <div onMouseMove={handleMouseMove} onMouseLeave={handleMouseLeave} className="relative">
            <Slider
                className="cursor-pointer"
                value={[currentTime]}
                max={duration}
                onValueChange={handleProgressChange}
                trackClassName={progressClassName}
            />

            {hoveredTime !== null && (
                <HamsaTooltip
                    className={`absolute bottom-3 left-0`}
                    side="top"
                    contentStyle={{ left: tooltipPosition.left }}
                    label={formatTime(hoveredTime)}
                    Component={<></>}
                    open={true}
                />
            )}
        </div>
    );
};
