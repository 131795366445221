import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './app/App';
import reportWebVitals from './reportWebVitals';
import { I18nextProvider } from 'react-i18next';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import ar from './locales/ar.json';
import en from './locales/en.json';
import './index.scss';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { MeetingExpired, NotFound } from 'pages';
import './assets/fonts/AmpleSoftPro-Bold.ttf';
import './assets/fonts/AmpleSoftPro-Medium.ttf';

function getLanguageFromQuery() {
    const url = new URL(window.location.href);
    const lang = url.searchParams.get('lang');
    return lang || 'ar'; // Default to 'en' if 'lang' is not in the URL
}

i18n.use(LanguageDetector).init({
    fallbackLng: 'en',
    lng: getLanguageFromQuery(),
    debug: true,
    resources: {
        en: {
            translation: en,
        },
        ar: {
            translation: ar,
        },
    },
});

const rootElement: HTMLElement = document.getElementById('root') as HTMLElement;
if (rootElement) {
    const root = ReactDOM.createRoot(rootElement);
    root.render(
        <I18nextProvider i18n={i18n}>
            <Router>
                <Routes>
                    <Route path="/preview/" Component={App} />
                    <Route path="/expired" Component={MeetingExpired} />
                    {/* <Route path="/" Component={NotFound} /> */}
                </Routes>
            </Router>
        </I18nextProvider>,
    );
} else {
    console.error('root element not found');
}

reportWebVitals();
