import { Backward10Seconds, Forward10Seconds } from 'assets/iconComponents';

interface MoveControllerProps {
    handleBackwardMove: () => void;
    handleForwardMove: () => void;
    iconsClassName: string;
}

export const MoveController = ({
    handleBackwardMove,
    handleForwardMove,
    iconsClassName,
}: MoveControllerProps) => {
    return (
        <>
            <Forward10Seconds
                size={18}
                onClick={handleForwardMove}
                className={`cursor-pointer ${iconsClassName} fill-none`}
            />
            <Backward10Seconds
                size={18}
                onClick={handleBackwardMove}
                className={`cursor-pointer ${iconsClassName} fill-none`}
            />
        </>
    );
};
