import React from 'react';
import { useTranslation } from 'react-i18next';
import './summary.scss';

interface MeetingSummaryProps {
    data: {
        summaryData: string;
    };
}

export const Summary: React.FC<MeetingSummaryProps> = ({ data: summaryData }: any) => {
    const { t } = useTranslation();

    return (
        summaryData && (
            <div className="meeting-summary">
                <h2 className="meeting-overview-heading"> 🔍 {t('summary')}</h2>
                <p className="meeting-overview-content">{summaryData}</p>
            </div>
        )
    );
};
