import { Check, Copy, Play } from 'assets/iconComponents';
import './transcriptItem.scss';
import moment from 'moment';
import { Dispatch, SetStateAction, useContext, useMemo, useState } from 'react';
import { MyContext } from 'app/context';
import { splitFullName } from 'utils/splitFullName';
import { getResponsiveIconSize } from 'utils/responsiveIconSize';
import { formatTime } from 'utils/formateTime';

interface TranscriptItemType {
    text: string;
    time: number;
}

type Transcript = {
    transcriptItems: TranscriptItemType[];
    speaker: string;
};

interface TranscriptItemProps {
    transcript: Transcript;
    nextTranscript?: Transcript;
    avatarBackgroundColor: string;
    url: string;
    duration: number;
    setPlayed: Dispatch<SetStateAction<number>>;
    setIsVideoPlaying: Dispatch<SetStateAction<boolean>>;
    wavesurfer: any;
    isVideoReady: boolean;
    isAudioReady: boolean;
    played: number;
    transcripts: Transcript[];
    setIsAudioPlaying: any;
    currentTime: number;
}

export const TranscriptItem = ({
    transcript,
    nextTranscript,
    avatarBackgroundColor,
    url,
    duration,
    setPlayed,
    setIsVideoPlaying,
    wavesurfer,
    isVideoReady,
    isAudioReady,
    played,
    transcripts,
    currentTime,
    setIsAudioPlaying,
}: TranscriptItemProps) => {
    const [copied, setCopied] = useState(false);

    const context = useContext(MyContext);

    const fileExtension = url?.split('.')?.pop()?.toLowerCase();

    const isVideo =
        fileExtension &&
        (fileExtension === 'mp3' || fileExtension === 'ogg' || fileExtension === 'wav')
            ? false
            : fileExtension &&
              (fileExtension === 'mp4' || fileExtension === 'webm' || fileExtension === 'avi')
            ? true
            : false;

    const playTranscript = (time: number): void => {
        if (isVideo && isVideoReady) {
            duration && setPlayed(time / duration);
            setIsVideoPlaying(true);
        } else if (isAudioReady && !isVideo) {
            wavesurfer?.setTime(time);
            wavesurfer?.play();
            setIsAudioPlaying(true);
        }
    };

    const copyTranscriptContent = () => {
        const transcriptContent = transcript.transcriptItems.map((item) => item.text).join(' ');
        navigator.clipboard.writeText(transcriptContent);
        setCopied(true);
        setTimeout(() => {
            setCopied(false);
        }, 1000);
    };

    const { firstName, lastName } = useMemo(
        () => splitFullName(transcript.speaker),
        [transcript.speaker],
    );

    return (
        <div className="transcript-item w-full items-start relative">
            <div className="initials-time-container">
                <div className="flex items-center">
                    <span className="initials" style={{ backgroundColor: avatarBackgroundColor }}>
                        {firstName[0] + lastName[0]}
                    </span>
                </div>
                <span className="time-offset">
                    {formatTime(transcript.transcriptItems[0]?.time)}{' '}
                </span>
            </div>
            <div className="content-container w-full">
                <div className="flex w-full items-center justify-between">
                    <span className="mr-0 font-bold  "> {transcript.speaker} :</span>

                    <div className="actions-container flex items-center ">
                        <button onClick={copyTranscriptContent} className="bg-lightBlue rounded-6 ">
                            {copied ? (
                                <Check size={getResponsiveIconSize(20)} />
                            ) : (
                                <Copy size={getResponsiveIconSize(20)} />
                            )}
                        </button>
                        <button
                            onClick={() => playTranscript(transcript.transcriptItems[0].time)}
                            className="bg-primary rounded-6 play-btn mx-2"
                        >
                            <Play size={getResponsiveIconSize(16)} />
                        </button>
                    </div>
                </div>
                <p className="content">
                    {transcript.transcriptItems.map((item, i) => {
                        const currentVideoTime = isVideo ? played / duration : currentTime;
                        const nextTimeStamp = transcript.transcriptItems[i + 1]?.time;
                        const nextTranscriptTimeStamp =
                            nextTranscript?.transcriptItems[0]?.time || 0;
                        const itemTime = item.time;
                        const isTranscriptActive = nextTimeStamp
                            ? currentVideoTime >= itemTime && currentVideoTime < nextTimeStamp
                            : currentVideoTime >= itemTime &&
                              currentVideoTime < nextTranscriptTimeStamp;

                        return (
                            <span
                                key={`${item.text + i}`}
                                onClick={() => {
                                    playTranscript(item.time);
                                }}
                                className={isTranscriptActive ? 'active' : ''}
                            >
                                {`${item.text} `}
                            </span>
                        );
                    })}
                </p>
            </div>
        </div>
    );
};
