import { useState } from 'react';
import { Check } from 'assets/iconComponents';
import './checkbox.scss';
import { getResponsiveIconSize } from 'utils/responsiveIconSize';

interface CheckboxProps {
    onChange?: (checked: boolean) => void;
    title: string;
    checked? : boolean;
}

export const Checkbox = ({ onChange, title, checked : isChecked }: CheckboxProps) => {
    const [checked, setChecked] = useState(isChecked);

    const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const isChecked = event.target.checked;
        setChecked(isChecked);
        onChange && onChange(isChecked);
    };

    return (
        <label className="flex checkbox-container items-center">
            <div className="w-7 h-7 rounded-4 border border-blue border-gray-400 ml-2 flex items-center justify-center">
                {checked && <Check size={getResponsiveIconSize(16)} />}
            </div>
            <input type="checkbox" className="hidden" checked={checked} onChange={handleOnChange} />
            <span className="mr-1 title">{title}</span>
        </label>
    );
};
