/* eslint-disable */
/* tslint:disable */
import PropTypes from 'prop-types';
import React from 'react';
const Mute = ({size, ...props}) => (
  <svg viewBox="0 0 14 17" fill="currentColor" width={ size || "14" } height={ size || "17" } {...props}>
    <path d="M0.89856 6.49298V10.2902C0.89856 12.1888 1.84786 13.1381 3.74646 13.1381H5.10395C5.45519 13.1381 5.80643 13.2425 6.11021 13.4229L8.88216 15.1601C11.2744 16.66 13.2394 15.5683 13.2394 12.7489V4.0343C13.2394 1.20539 11.2744 0.123186 8.88216 1.62308L6.11021 3.3603C5.80643 3.54066 5.45519 3.64509 5.10395 3.64509H3.74646C1.84786 3.64509 0.89856 4.59438 0.89856 6.49298Z"
      stroke="currentColor" strokeWidth="1.117" />
  </svg>
);
Mute.displayName = 'Mute';
Mute.propTypes = {
  size: PropTypes.string
}
export default Mute;
/* tslint:enable */
/* eslint-enable */
