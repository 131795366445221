import {
    ExpandController,
    MoveController,
    PlayController,
    ProgressBar,
    SpeedController,
    TimeTracker,
    VolumeController,
} from '.';

interface PlayControllerProps {
    handlePlayPause: () => void;
    duration: number;
    handleProgressChange: (value: number[]) => void;
    speed: number;
    handleSpeed: (speed: number) => void;
    volume: number;
    handleVolumeChange: (value: number[]) => void;
    handleMute: () => void;
    handleUnMute: () => void;
    handleBackwardMove: () => void;
    handleForwardMove: () => void;
    isPlaying: boolean;
    iconsClassName: string;
    currentTime: number;
    progressClassName: string;
    volumeClassName: string;
    handleReply: () => void;
    isVideo: boolean;
    handleExpand?: () => void;
}

export const PlayerController = ({
    handlePlayPause,
    duration,
    handleProgressChange,
    speed,
    handleSpeed,
    volume,
    handleVolumeChange,
    handleMute,
    handleUnMute,
    handleBackwardMove,
    handleForwardMove,
    isPlaying,
    iconsClassName,
    currentTime,
    progressClassName,
    volumeClassName,
    handleReply,
    isVideo,
    handleExpand,
}: PlayControllerProps) => {
    return (
        <div
            className={`flex-col gap-[10px] px-[15px] py-[10px] bottom-0 w-full absolute ${
                isVideo ? 'hidden group-hover:flex' : 'flex'
            } `}
        >
            <ProgressBar
                handleProgressChange={handleProgressChange}
                currentTime={currentTime}
                duration={duration}
                progressClassName={progressClassName}
            />
            <div className="flex justify-between items-center">
                <div className="flex items-center gap-[12px]">
                    {isVideo && handleExpand && (
                        <ExpandController
                            iconsClassName={iconsClassName}
                            handleExpand={handleExpand}
                        />
                    )}
                    <SpeedController
                        speed={speed}
                        handleSpeed={handleSpeed}
                        iconsClassName={iconsClassName}
                    />
                </div>
                <div className="flex gap-[12px] items-center">
                    <TimeTracker
                        duration={duration}
                        currentTime={currentTime}
                        iconsClassName={iconsClassName}
                    />
                    <VolumeController
                        volume={volume}
                        handleMute={handleMute}
                        handleUnMute={handleUnMute}
                        handleVolumeChange={handleVolumeChange}
                        iconsClassName={iconsClassName}
                        volumeClassName={volumeClassName}
                    />
                    <MoveController
                        handleBackwardMove={handleBackwardMove}
                        handleForwardMove={handleForwardMove}
                        iconsClassName={iconsClassName}
                    />
                    <PlayController
                        handlePlayPause={handlePlayPause}
                        isPlaying={isPlaying}
                        iconsClassName={iconsClassName}
                        currentTime={currentTime}
                        handleReply={handleReply}
                        duration={duration}
                    />
                </div>
            </div>
        </div>
    );
};
