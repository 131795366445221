import React, { useContext, useState } from 'react';
import { SpeechTimeline } from '../speechTimeline';
import Player from 'video.js/dist/types/player';
import '../player/player.scss';
// @ts-ignore
import HamsaLogo from '../../assets/icons/Hamsa.svg';

import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import './videoSpeech.scss';
import { Globe } from 'assets/iconComponents';
import { Dropdown } from 'components/dropdown';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { getResponsiveIconSize } from 'utils/responsiveIconSize';
import { MyContext } from 'app/context';
import { AudioPlayer, VideoPlayer } from 'components/player';
import { Video } from 'components/video';

interface VideoSpeechProps {
    meetingInfo: any;
}

export const VideoSpeech = ({
    meetingInfo = { meeting_info: { name: '' } },
    wavesurfer,
    played,
    setPlayed,
    duration,
    setDuration,
    isVideoPlaying,
    setIsVideoPlaying,
    setWavesurfer,
    setCurrentTime,
    currentTime,
    setIsAudioPlaying,
    isVideoReady,
    setIsVideoReady,
    isAudioReady,
    setIsAudioReady,
    isAudioPlaying,
}: any) => {
    const [meetingDuration, setMeetingDuration] = useState<number | undefined>(0);

    const { t } = useTranslation();
    const context = useContext(MyContext);
    const playerRef = React.useRef<any>(null);

    const url = meetingInfo?.url;
    const fileExtension = url?.split('.')?.pop()?.toLowerCase();

    const urlType =
        fileExtension &&
        (fileExtension === 'mp3' || fileExtension === 'ogg' || fileExtension === 'wav')
            ? 'audio/mp3'
            : fileExtension &&
              (fileExtension === 'mp4' || fileExtension === 'webm' || fileExtension === 'avi')
            ? 'video/mp4'
            : '';

    const isVideo =
        fileExtension &&
        (fileExtension === 'mp3' || fileExtension === 'ogg' || fileExtension === 'wav')
            ? false
            : fileExtension &&
              (fileExtension === 'mp4' || fileExtension === 'webm' || fileExtension === 'avi')
            ? true
            : false;

    const videoJsOptions = {
        autoplay: true,
        controls: true,
        responsive: true,
        fluid: true,
        sources: [
            {
                src: url,
                type: urlType,
            },
        ],
        controlBar: { children: ['progressControl'] },
    };

    const handlePlayerReady = (player: Player) => {
        playerRef.current = player;
        player.on('durationchange', function () {
            const videoDuration = player.duration();
            setMeetingDuration(videoDuration);
        });
    };

    const getMeetingStartTime = () => {
        return moment(parseInt(meetingInfo?.usageTime)).format('MMM DD, YYYY - h:mm A');
    };

    const toggleLanguage = (newLanguage: string) => {
        if (i18n.language === newLanguage) return;
        i18n.changeLanguage(newLanguage);
        context.updateConversationId(uuidv4());
    };

    const speakersList = meetingInfo?.jobResponse?.diarization.map((item: any) => {
        return {
            speaker: item.speaker,
            timeSlots: { start: item.start, end: item.end },
        };
    });

    return (
        <>
            <div className="flex">
                <div className="meeting-title-logo w-full relative  mb-6 flex items-center justify-between">
                    <div className="flex">
                        <img alt="logo" className="logo" src={HamsaLogo} />
                        <div className="flex pr-5 flex-col mr-4 title-date-container ">
                            <span className="title">{meetingInfo?.meeting_info?.name}</span>
                            <span className="date">{getMeetingStartTime()}</span>
                        </div>
                    </div>
                    {/* <button className="bg-primary px-5 text-white rounded-8 items-center copy-link-btn p-3 flex">
                        <Link size={17} />
                        <span className="pl-3 mx-2 btn-title">{t('copyLink')}</span>
                        <ChevronDown size={17} />
                    </button> */}
                    <div className="absolute dropdown-container">
                        <Dropdown
                            dropdownClass="lang-dropdown"
                            dropdownBtn={
                                <span className="lang-btn">
                                    <Globe size={getResponsiveIconSize(22)} />
                                </span>
                            }
                        >
                            <ul className="lang-list">
                                <li onClick={() => toggleLanguage('ar')}>{t('arabic')}</li>
                                <li onClick={() => toggleLanguage('en')}>{t('english')}</li>
                            </ul>
                        </Dropdown>
                    </div>
                </div>
            </div>
            {isVideo ? (
                <VideoPlayer
                    played={played}
                    setPlayed={setPlayed}
                    duration={duration}
                    setDuration={setDuration}
                    isPlaying={isVideoPlaying}
                    setIsPlaying={setIsVideoPlaying}
                    isVideoReady={isVideoReady}
                    setIsVideoReady={setIsVideoReady}
                    url={url}
                />
            ) : (
                <AudioPlayer
                    setWavesurfer={setWavesurfer}
                    setCurrentTime={setCurrentTime}
                    currentTime={currentTime}
                    setIsAudioPlaying={setIsAudioPlaying}
                    url={url}
                    isAudioReady={isAudioReady}
                    setIsAudioReady={setIsAudioReady}
                    isAudioPlaying={isAudioPlaying}
                    setDuration={setDuration}
                />
            )}
            <SpeechTimeline
                speakersList={speakersList}
                meetingDuration={duration}
                isVideo={isVideo}
                setPlayed={setPlayed}
                setIsVideoPlaying={setIsVideoPlaying}
                wavesurfer={wavesurfer}
                isVideoReady={isVideoReady}
                isAudioReady={isAudioReady}
                setIsAudioPlaying={setIsAudioPlaying}
            />
        </>
    );
};
