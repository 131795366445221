import { useEffect, useRef, useState } from 'react';

interface SpeedControllerProps {
    speed: number;
    handleSpeed: (speed: number) => void;
    iconsClassName: string;
}

export const SpeedController = ({ speed, handleSpeed, iconsClassName }: SpeedControllerProps) => {
    const [showSpeedList, setShowSpeedList] = useState<boolean>(false);

    const speedList = useRef<HTMLDivElement>(null);
    const speedTrigger = useRef<HTMLDivElement>(null);

    const speeds: number[] = [2, 1.5, 1.25, 1, 0.75, 0.5];

    const handleSpeedChange = (speedOption: any) => {
        setShowSpeedList(false);
        handleSpeed(speedOption);
    };

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                speedList?.current &&
                speedTrigger?.current &&
                !speedList?.current?.contains(event.target as Node) &&
                !speedTrigger?.current?.contains(event.target as Node)
            ) {
                setShowSpeedList(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <>
            <span
                ref={speedTrigger}
                onClick={() => setShowSpeedList((prev) => !prev)}
                className={`text-[13px] ${iconsClassName} quicksand cursor-pointer`}
            >
                x{speed}
            </span>
            {showSpeedList && (
                <div
                    ref={speedList}
                    className="z-50 rounded-[8px] absolute bottom-[50px] gap-[4px] right-[5px] flex flex-col items-center w-[80px] h-fit overflow-y-auto no-scroll bg-[rgba(0,0,0,0.5)]"
                >
                    {speeds.map((speedOption) => (
                        <div
                            key={speedOption}
                            onClick={() => handleSpeedChange(speedOption)}
                            className={`${
                                speedOption === speed && 'bg-[rgba(169,169,169,1)]'
                            } rounded-[8px] quicksand hover:bg-[rgba(169,169,169,1)] p-[4px] flex justify-center items-center w-full hover:text-white text-[12px] text-white cursor-pointer`}
                        >
                            {speedOption}
                        </div>
                    ))}
                </div>
            )}
        </>
    );
};
