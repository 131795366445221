import { CSSProperties, ReactElement, useState } from 'react';
import {
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
    Tooltip,
    Arrow,
} from '@radix-ui/react-tooltip';

interface HamsaTooltipProps {
    label: string;
    Component: ReactElement;
    className?: string;
    contentStyle?: CSSProperties;
    open?: boolean;
    side?: 'top' | 'right' | 'bottom' | 'left' | undefined;
}

export const HamsaTooltip = ({
    Component,
    label,
    className = '',
    side = 'bottom',
    open = false,
    contentStyle = {},
}: HamsaTooltipProps) => {
    const [isOpen, setIsOpen] = useState(open);

    return (
        <TooltipProvider>
            <Tooltip delayDuration={0} open={isOpen} onOpenChange={setIsOpen}>
                <TooltipTrigger
                    onClick={() => {
                        setIsOpen(true);
                    }}
                    className={`flex justify-center items-center ${className}`}
                >
                    {Component}
                </TooltipTrigger>
                {label && (
                    <TooltipContent
                        side={side}
                        style={contentStyle}
                        className={`bg-black p-[4px] rounded-[3px] quicksand text-[10px] font-light leading-[13px] tracking-0 text-white flex z-50 max-w-[200px] relative`}
                    >
                        {label}
                        <Arrow className="TooltipArrow" />
                    </TooltipContent>
                )}
            </Tooltip>
        </TooltipProvider>
    );
};
