/* eslint-disable */
/* tslint:disable */
import PropTypes from 'prop-types';
import React from 'react';
const Search = ({size, ...props}) => (
  <svg viewBox="0 0 15 15" fill="currentColor" width={ size || "15" } height={ size || "15" } {...props}>
    <path d="M7.8125 13.125C4.53331 13.125 1.875 10.4667 1.875 7.1875 1.875 3.90831 4.53331 1.25 7.8125 1.25 11.0917 1.25 13.75 3.90831 13.75 7.1875 13.75 10.4667 11.0917 13.125 7.8125 13.125zM1.25 13.75L2.5 12.5"
      stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
Search.displayName = 'Search';
Search.propTypes = {
  size: PropTypes.string
}
export default Search;
/* tslint:enable */
/* eslint-enable */
