/* eslint-disable */
/* tslint:disable */
import PropTypes from 'prop-types';
import React from 'react';
const Pause = ({size, ...props}) => (
  <svg viewBox="0 0 16 16" fill="currentColor" width={ size || "16" } height={ size || "16" } {...props}>
    <path d="M7.1 12.74V3.26C7.1 2.36 6.72 2 5.76 2H3.34C2.38 2 2 2.36 2 3.26V12.74C2 13.64 2.38 14 3.34 14H5.76C6.72 14 7.1 13.64 7.1 12.74zM13.9999 12.74V3.26C13.9999 2.36 13.6199 2 12.6599 2H10.2399C9.28657 2 8.8999 2.36 8.8999 3.26V12.74C8.8999 13.64 9.2799 14 10.2399 14H12.6599C13.6199 14 13.9999 13.64 13.9999 12.74z"
    />
  </svg>
);
Pause.displayName = 'Pause';
Pause.propTypes = {
  size: PropTypes.string
}
export default Pause;
/* tslint:enable */
/* eslint-enable */
