import axios from 'axios';

const apiURL = 'https://api.tryhamsa.com/api/v1';
// const apiURL = 'https://api.ejaz.ai';

const apiInstance = axios.create({
    baseURL: apiURL,
});

export default apiInstance;
