import { useEffect, useRef, useState } from 'react';
import { Slider } from '../../slider';
import { MutedVolume, Volume } from 'assets/iconComponents';

interface VolumeControllerProps {
    volume: number;
    handleMute: () => void;
    handleUnMute: () => void;
    handleVolumeChange: (value: number[]) => void;
    iconsClassName: string;
    volumeClassName: string;
}

export const VolumeController = ({
    volume,
    handleMute,
    handleUnMute,
    handleVolumeChange,
    iconsClassName,
    volumeClassName,
}: VolumeControllerProps) => {
    const [showVolumeSlider, setShowVolumeSlider] = useState(false);

    const timeoutRef = useRef<NodeJS.Timeout | null>(null);

    const handleMouseEnter = () => {
        setShowVolumeSlider(true);
        setTimeout(() => setShowVolumeSlider(false), 2000);
    };

    const handleMouseLeave = () => setShowVolumeSlider(false);

    const resetTimeout = () => {
        if (timeoutRef?.current) clearTimeout(timeoutRef.current);
        timeoutRef.current = setTimeout(() => {
            setShowVolumeSlider(false);
        }, 2000);
    };

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key === 'ArrowUp') {
                event.preventDefault();
                setShowVolumeSlider(true);
                resetTimeout();
            } else if (event.key === 'ArrowDown') {
                event.preventDefault();
                setShowVolumeSlider(true);
                resetTimeout();
            }
        };

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
            clearTimeout(timeoutRef.current!);
        };
    }, []);

    return (
        <div className="flex gap-[12px] group/item items-center cursor-pointer mr-[-10px]">
            <div
                className={`group-hover/item:opacity-100 transform-opacity duration-700 ease-out ${
                    showVolumeSlider ? 'opacity-100' : 'opacity-0'
                }`}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >
                <Slider
                    onValueChange={handleVolumeChange}
                    value={[volume]}
                    min={0}
                    max={1}
                    step={0.1}
                    trackClassName={volumeClassName}
                    thumbClassName={`${
                        showVolumeSlider ? 'block' : 'hidden'
                    } group-hover/item:block`}
                    className={`${
                        showVolumeSlider ? 'opacity-100 w-[40px] mr-[10px] h-2' : 'h-0 w-0'
                    } group-hover/item:w-[40px] group-hover/item:opacity-100 group-hover/item:mr-[10px] group-hover/item:h-2 transform-width transition-width duration-500 ease-out`}
                />
            </div>
            {volume ? (
                <Volume
                    className={`${iconsClassName} cursor-pointer fill-none`}
                    size="18"
                    onClick={handleMute}
                />
            ) : (
                <MutedVolume
                    className={`${iconsClassName} cursor-pointer fill-none`}
                    size="18"
                    onClick={handleUnMute}
                />
            )}
        </div>
    );
};
