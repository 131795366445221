/* eslint-disable */
/* tslint:disable */
import PropTypes from 'prop-types';
import React from 'react';
const Expand = ({size, ...props}) => (
  <svg viewBox="0 0 16 16" fill="currentColor" width={ size || "16" } height={ size || "16" } {...props}>
    <path d="M11.3333 2H13.9999V4.66667M10 6L14 2M4.66667 13.9997H2V11.333M6 9.99902L2 13.999M9.3466 1.46635C8.91326 1.37968 8.45992 1.33301 7.99992 1.33301 4.31992 1.33301 1.33325 4.31967 1.33325 7.99967 1.33325 8.45967 1.37993 8.90634 1.46659 9.33301M6.65332 14.5327C7.08665 14.6193 7.54 14.666 8 14.666 11.68 14.666 14.6667 11.6793 14.6667 7.99935 14.6667 7.54602 14.62 7.09935 14.5333 6.66602"
    />
  </svg>
);
Expand.displayName = 'Expand';
Expand.propTypes = {
  size: PropTypes.string
}
export default Expand;
/* tslint:enable */
/* eslint-enable */
