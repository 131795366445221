/* eslint-disable */
/* tslint:disable */
import PropTypes from 'prop-types';
import React from 'react';
const ChevronDown = ({size, ...props}) => (
  <svg viewBox="0 0 17 17" fill="currentColor" width={ size || "17" } height={ size || "17" } {...props}>
    <path d="M5 7L8.5 11L12 7" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
ChevronDown.displayName = 'ChevronDown';
ChevronDown.propTypes = {
  size: PropTypes.string
}
export default ChevronDown;
/* tslint:enable */
/* eslint-enable */
