import { useContext, useEffect, useState } from 'react';
import { MeetingInfo } from '../../components/meetingInfo';
import { VideoSpeech } from '../../components/videoSpeech';
import './meeting.scss';
import { fetchMeetingDetails } from 'services/api/meeting';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Lottie from 'react-lottie';
import animationData from '../../assets/lottie/loading.json';
import { v4 as uuidv4 } from 'uuid';
import { MyContext } from 'app/context';

export const Meeting = () => {
    const [meetingInfo, setMeetingInfo] = useState<any>({});
    const [summary, setSummary] = useState<string>('');
    const [text, setText] = useState<string>('');
    const [transcripts, setTranscripts] = useState<any>([]);
    const [articles, setArticles] = useState<any>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [played, setPlayed] = useState<number>(0);
    const [duration, setDuration] = useState<number>(0);
    const [isVideoPlaying, setIsVideoPlaying] = useState<boolean>(false);
    const [wavesurfer, setWavesurfer] = useState(null);
    const [currentTime, setCurrentTime] = useState(0);
    const [isAudioPlaying, setIsAudioPlaying] = useState<boolean>(false);
    const [isVideoReady, setIsVideoReady] = useState(false);
    const [isAudioReady, setIsAudioReady] = useState(false);

    const searchParams = new URLSearchParams(useLocation().search);
    let lang = searchParams.get('lang') || '';
    const token = searchParams.get('token') || '';

    const { i18n } = useTranslation();
    const navigate = useNavigate();

    const context = useContext(MyContext);
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
        },
    };

    const fetchMeetingInfo = async () => {
        try {
            const res = await fetchMeetingDetails(token);
            setMeetingInfo(res?.data);
            setTranscripts(res?.data?.jobResponse?.diarization_with_chunks || []);
            setSummary(res?.data?.jobResponse?.summary);
            setText(res?.data?.jobResponse?.text);
            setArticles(res?.data?.jobResponse?.articles || []);
        } catch (error: any) {
            if (error?.response?.status === 401) navigate('/expired');
            else navigate('/');
        }
    };

    const fetchAllData = async () => {
        try {
            setLoading(true);
            await Promise.all([fetchMeetingInfo()]);
            setLoading(false);
        } catch (err: any) {
            if (err.response.status === 401) {
                navigate('/expired');
            } else {
                navigate('/');
            }
        }
    };

    const changeDirectionBasedOnLanguage = () => {
        if (lang === 'en') {
            document.body.dir = 'ltr';
        } else {
            document.body.dir = 'rtl';
        }
    };

    useEffect(() => {
        fetchAllData();
        changeDirectionBasedOnLanguage();

        i18n.on('languageChanged', (lng) => {
            lang = lng;
            const url = new URL(window.location.href);
            url.searchParams.set('lang', lang);
            window.history.pushState({}, '', url.toString());
            fetchAllData();
            changeDirectionBasedOnLanguage();
        });

        context.updateConversationId(uuidv4());
        return () => {
            i18n.off('languageChanged');
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return loading ? (
        <div className="loader-container">
            <span className="loader">
                <Lottie options={defaultOptions} height={400} width={400} />
            </span>
        </div>
    ) : (
        <div className="meeting-container">
            <div className="meeting-info-container">
                <MeetingInfo
                    transcripts={transcripts}
                    summaryData={summary}
                    meetingInfo={meetingInfo}
                    transcriptsText={text}
                    articles={articles}
                    url={meetingInfo?.url}
                    duration={duration}
                    setPlayed={setPlayed}
                    setIsVideoPlaying={setIsVideoPlaying}
                    wavesurfer={wavesurfer}
                    isVideoReady={isVideoReady}
                    isAudioReady={isAudioReady}
                    played={played}
                    currentTime={currentTime}
                    setCurrentTime={setCurrentTime}
                    setIsAudioPlaying={setIsAudioPlaying}
                />
            </div>
            <div className="video-speech-container bg-light">
                <VideoSpeech
                    wavesurfer={wavesurfer}
                    played={played}
                    setPlayed={setPlayed}
                    duration={duration}
                    setDuration={setDuration}
                    isVideoPlaying={isVideoPlaying}
                    setIsVideoPlaying={setIsVideoPlaying}
                    setWavesurfer={setWavesurfer}
                    setCurrentTime={setCurrentTime}
                    currentTime={currentTime}
                    setIsAudioPlaying={setIsAudioPlaying}
                    meetingInfo={meetingInfo}
                    isVideoReady={isVideoReady}
                    setIsVideoReady={setIsVideoReady}
                    isAudioReady={isAudioReady}
                    setIsAudioReady={setIsAudioReady}
                    isAudioPlaying={isAudioPlaying}
                />
            </div>
        </div>
    );
};
