import { Pause, Play, RotateLeft as Replay } from 'assets/iconComponents';

interface PlayControllerProps {
    handlePlayPause: () => void;
    isPlaying: boolean;
    iconsClassName: string;
    currentTime: number;
    handleReply: () => void;
    duration: number;
}

export const PlayController = ({
    handlePlayPause,
    isPlaying,
    iconsClassName,
    currentTime,
    handleReply,
    duration,
}: PlayControllerProps) => {
    return (
        <div
            className="flex justify-center items-center cursor-pointer w-[16px] h-[16px]"
            onClick={handlePlayPause}
        >
            {currentTime && duration && currentTime === duration ? (
                <Replay onClick={handleReply} size="18" className="fill-none" />
            ) : isPlaying ? (
                <Pause className={`${iconsClassName} fill-none`} size="18" />
            ) : (
                <Play size={'20'} className={`${iconsClassName} fill-none`} />
            )}
        </div>
    );
};
