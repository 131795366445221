import { Questions, TranscriptItem } from './index';
import { Dropdown } from 'components/dropdown';
import { useTranslation } from 'react-i18next';
import { Clear, Search } from 'assets/iconComponents';
import { Checkbox } from 'components/checkbox/Checkbox';
import './transcripts.scss';
import { randomColors } from 'constants/randomColors';
import { getResponsiveIconSize } from 'utils/responsiveIconSize';
import { Dispatch, SetStateAction, useRef, useState } from 'react';

interface TranscriptsProps {
    data: {
        transcripts: any[];
        transcriptsText: string;
        url: string;
        duration: number;
        setPlayed: Dispatch<SetStateAction<number>>;
        setIsVideoPlaying: Dispatch<SetStateAction<boolean>>;
        wavesurfer: any;
        isVideoReady: boolean;
        isAudioReady: boolean;
        played: number;
        setIsAudioPlaying: any;
        currentTime: number;
        setCurrentTime: Dispatch<SetStateAction<number>>;
    };
    onQuestionClick: () => void;
}

const mapTranscripts = (inputString: string) => {
    const regex = /\[([\d.]+)\]\s(.*?)(?=\[([\d.]+)\]|$)/g;

    const result: { text: string; time: number }[] = [];
    let match;

    while ((match = regex.exec(inputString)) !== null) {
        const startTime = parseFloat(match[1]);
        const text = match[2];

        result.push({ text, time: startTime });
    }

    return result;
};

export const Transcripts = ({
    data: {
        transcripts,
        transcriptsText,
        url,
        duration,
        setPlayed,
        setIsVideoPlaying,
        wavesurfer,
        isVideoReady,
        isAudioReady,
        played,
        setIsAudioPlaying,
        currentTime,
    },
    onQuestionClick,
}: TranscriptsProps) => {
    const { t } = useTranslation();
    const [searchKeyword, setSearchKeyword] = useState('');
    const [filteredAttendees, setFilteredAttendees] = useState<any[]>([]);

    const questionsList = [
        t('whatIsTheNextStep'),
        t('whoAreTheParticipants'),
        t('whatAreWeGoingToTalkAboutInTheNextSession'),
    ];

    const transcriptsList = transcripts.map((t, i) => ({
        speaker: t.speaker,
        transcriptItems: mapTranscripts(t.text),
        avatarBackgroundColor: randomColors[i % randomColors.length],
        // timeDuration: [t.start, t.end],
    }));
    const transcriptsClone = [...transcriptsList];

    const getDistinctUsers = () => {
        const distinctUsersArr = [...new Set(transcriptsList?.map((t) => t.speaker))];
        return distinctUsersArr;
    };

    const getUserColor = (user: any) => {
        const index = getDistinctUsers().findIndex((u) => u === user);
        return randomColors[index];
    };

    const renderTranscriptsList = () => {
        let transcriptItemsList = [...transcriptsClone];
        if (searchKeyword) {
            transcriptItemsList = transcriptsClone.filter((transcript) =>
                transcript.transcriptItems.some((item) =>
                    item.text.toLowerCase().includes(searchKeyword.toLowerCase()),
                ),
            );
        }
        if (filteredAttendees.length > 0) {
            transcriptItemsList = transcriptsClone.filter((transcript) =>
                filteredAttendees.includes(transcript.speaker.toLowerCase()),
            );
        }
        return !!transcriptItemsList.length ? (
            transcriptItemsList
                .filter((t) => t.transcriptItems.length > 0)
                .map((transcript, i, arr) => (
                    <TranscriptItem
                        key={i}
                        transcript={transcript}
                        nextTranscript={arr[i + 1]}
                        transcripts={transcriptsList}
                        avatarBackgroundColor={getUserColor(transcript.speaker)}
                        url={url}
                        duration={duration}
                        setPlayed={setPlayed}
                        setIsVideoPlaying={setIsVideoPlaying}
                        wavesurfer={wavesurfer}
                        isVideoReady={isVideoReady}
                        isAudioReady={isAudioReady}
                        played={played}
                        setIsAudioPlaying={setIsAudioPlaying}
                        currentTime={currentTime}
                    />
                ))
        ) : (
            <p className="no-result absolute">{t('noResultsFound')}</p>
        );
    };

    const toggleFilteredAttendee = (attendee: string, isChecked: boolean) => {
        let filteredAttendeesClone = [...filteredAttendees];
        if (!isChecked) {
            filteredAttendeesClone = filteredAttendeesClone.filter(
                (a) => a.toLowerCase() !== attendee.toLowerCase(),
            );
        } else {
            filteredAttendeesClone.push(attendee.toLowerCase());
        }
        setFilteredAttendees(filteredAttendeesClone);
    };

    return (
        <div className="transcripts-container">
            {transcripts.length !== 0 && (
                <div className="flex px-4 items-center mt-7 justify-between ">
                    <div className="search-input-container">
                        <Search size={getResponsiveIconSize(15)} />
                        <input
                            onChange={(e) => setSearchKeyword(e.target.value)}
                            placeholder={t('searchHere')}
                            className="search-transcript-input rounded-8"
                            value={searchKeyword}
                            maxLength={1000}
                        />
                        {!!searchKeyword && (
                            <span className="clear-search">
                                <Clear
                                    size={getResponsiveIconSize(22)}
                                    onClick={() => setSearchKeyword('')}
                                />
                            </span>
                        )}
                    </div>
                    <div className="flex">
                        <div className="mx-2">
                            <Dropdown closeOnClickInside={false} title={t('participants')}>
                                <ul>
                                    {getDistinctUsers().map((user: any) => (
                                        <Checkbox
                                            onChange={(checked) =>
                                                toggleFilteredAttendee(user, checked)
                                            }
                                            checked={filteredAttendees.includes(user.toLowerCase())}
                                            title={user}
                                        />
                                    ))}
                                </ul>
                            </Dropdown>
                        </div>
                    </div>
                </div>
            )}
            {transcripts.length ? (
                <div className="transcript-list">{renderTranscriptsList()}</div>
            ) : (
                <div className="p-[20px]">{transcriptsText}</div>
            )}

            <div className="questions">
                {/* <Questions onClick={onQuestionClick} questionsList={questionsList} /> */}
            </div>
        </div>
    );
};
