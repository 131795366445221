import { formatTime } from 'utils/formateTime';

interface TimeTrackerProps {
    duration: number;
    currentTime: number;
    iconsClassName: string;
}

export const TimeTracker = ({ duration, currentTime, iconsClassName }: TimeTrackerProps) => (
    <span className={`text-[13px] ${iconsClassName} quicksand mt-[1px]`}>
        {`${formatTime(duration)} / ${formatTime(currentTime)}`}
    </span>
);
