/* eslint-disable */
/* tslint:disable */
import PropTypes from 'prop-types';
import React from 'react';
const Replay = ({ size = '40', ...props }) => (
    <svg
        viewBox="0 0 40 40"
        fill="currentColor"
        width={size || '40'}
        height={size || '40'}
        {...props}
    >
        <path
            opacity=".4"
            d="M15.1831 8.46667C16.6331 8.03333 18.2331 7.75 19.9998 7.75C27.9831 7.75 34.4498 14.2167 34.4498 22.2C34.4498 30.1833 27.9831 36.65 19.9998 36.65C12.0165 36.65 5.5498 30.1833 5.5498 22.2C5.5498 19.2333 6.4498 16.4667 7.98314 14.1667"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M13.1167 8.86634L17.9334 3.33301M13.1167 8.86621L18.7334 12.9662"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
Replay.displayName = 'Replay';
Replay.propTypes = {
    size: PropTypes.string,
};
export default Replay;
/* tslint:enable */
/* eslint-enable */
