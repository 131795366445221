import { Dispatch, KeyboardEventHandler, SetStateAction, useEffect, useRef, useState } from 'react';
import { PlayerController } from './controllers';
import WaveSurfer from 'wavesurfer.js';
import { peaks } from './peeks';
import { LoadingAnimation } from 'components/loadingAnimation';

interface AudioPlayerProps {
    setWavesurfer: any;
    setCurrentTime: Dispatch<SetStateAction<number>>;
    currentTime: number;
    setIsAudioPlaying: Dispatch<SetStateAction<boolean>>;
    url: string;
    isAudioReady: boolean;
    setIsAudioReady: Dispatch<SetStateAction<boolean>>;
    isAudioPlaying: boolean;
    setDuration: any;
}

export const AudioPlayer = ({
    setWavesurfer,
    setCurrentTime,
    currentTime,
    setIsAudioPlaying,
    url,
    isAudioReady,
    setIsAudioReady,
    isAudioPlaying,
    setDuration,
}: AudioPlayerProps) => {
    const [speed, setSpeed] = useState<number>(1);
    const [volume, setVolume] = useState<number>(1);

    const playerContainer = useRef<HTMLDivElement | null>(null);
    const wavesurfer = useRef<WaveSurfer | null>(null);

    useEffect(() => {
        const audio = new Audio(url);

        const loadedMetadataHandler = () => setIsAudioReady(true);

        audio.addEventListener('loadedmetadata', loadedMetadataHandler);

        wavesurfer.current = WaveSurfer.create({
            container: playerContainer.current!,
            height: 100,
            waveColor: '#717D8F',
            progressColor: 'black',
            media: audio,
            barWidth: 2,
            barGap: 3,
            cursorWidth: 0,
            dragToSeek: true,
            barRadius: 7,
            hideScrollbar: true,
            backend: 'MediaElement',
            cursorColor: 'navy',
            barHeight: 1,
            autoScroll: true,
            mediaControls: true,
            autoCenter: true,
            interact: true,
            peaks,
        });

        setWavesurfer(wavesurfer.current);

        wavesurfer.current.on('ready', (duration) => {
            setDuration(duration);
        });

        wavesurfer.current.on('timeupdate', (time) => {
            setCurrentTime(time);
        });

        wavesurfer.current.on('interaction', () => {
            if (!isAudioPlaying) {
                wavesurfer?.current?.play();
                setIsAudioPlaying(true);
            }
        });

        return () => {
            wavesurfer?.current?.destroy();
            audio.removeEventListener('loadedmetadata', loadedMetadataHandler);
        };
    }, []);

    const handleProgressChange = (value: number[]) => wavesurfer?.current?.setTime(+value[0]);
    const handlePlayPause = () => {
        wavesurfer?.current?.playPause();
        setIsAudioPlaying((prev) => !prev);
    };

    const handleBackwardMove = () => wavesurfer?.current?.skip(-10);
    const handleForwardMove = () => wavesurfer?.current?.skip(10);
    const handleReply = () => wavesurfer?.current?.setTime(0);
    const handleMute = () => {
        setVolume(0);
        wavesurfer?.current?.setVolume(0);
    };
    const handleUnMute = () => {
        setVolume(1);
        wavesurfer?.current?.setVolume(1);
    };
    const handleVolumeChange = (value: number[]) => {
        setVolume(+value[0]);
        wavesurfer?.current?.setVolume(+value[0]);
    };
    const handleSpeed = (speedOption: number) => {
        setSpeed(speedOption);
        wavesurfer?.current?.setPlaybackRate(speedOption);
    };
    const handleVolumeUp = () => {
        setVolume((prev) => {
            wavesurfer?.current?.setVolume(Math.min(prev + 0.1, 1));
            return Math.min(prev + 0.1, 1);
        });
    };
    const handleVolumeDown = () => {
        setVolume((prev) => {
            wavesurfer?.current?.setVolume(Math.max(prev - 0.1, 0));
            return Math.max(prev - 0.1, 0);
        });
    };
    const handleKeyDown: KeyboardEventHandler<HTMLDivElement> = (event) => {
        if (event.key === 'ArrowLeft') {
            handleBackwardMove();
            event.preventDefault();
        } else if (event.key === 'ArrowRight') {
            handleForwardMove();
            event.preventDefault();
        } else if (event.key === ' ') {
            handlePlayPause();
            event.preventDefault();
        }
    };

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key === 'ArrowUp') {
                event.preventDefault();
                handleVolumeUp();
            } else if (event.key === 'ArrowDown') {
                event.preventDefault();
                handleVolumeDown();
            }
        };

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    return (
        <div
            className={`relative rounded-[7px] bg-[#E2EDFF]
             group outline-none h-[25rem] w-[62.4rem] rounder-[0.9rem]`}
            tabIndex={0}
            onKeyDown={handleKeyDown}
        >
            <div
                className="rounded-[7px] px-[20px] pt-[20px] pb-[60px] h-full flex justify-center items-center audio-container"
                ref={playerContainer}
            />
            {isAudioReady ? (
                <PlayerController
                    handlePlayPause={handlePlayPause}
                    handleForwardMove={handleForwardMove}
                    handleBackwardMove={handleBackwardMove}
                    handleVolumeChange={handleVolumeChange}
                    handleUnMute={handleUnMute}
                    handleMute={handleMute}
                    handleSpeed={handleSpeed}
                    volume={volume}
                    speed={speed}
                    duration={wavesurfer?.current?.getDuration() || 0}
                    currentTime={currentTime}
                    progressClassName="bg-[#B8C2DC]"
                    volumeClassName="bg-[#B8C2DC]"
                    handleProgressChange={handleProgressChange}
                    iconsClassName="stroke-[#131313] text-[#131313]"
                    isPlaying={isAudioPlaying}
                    handleReply={handleReply}
                    isVideo={false}
                />
            ) : (
                <LoadingAnimation className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[5px] h-[50px]" />
            )}
        </div>
    );
};
