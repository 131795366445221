import { Meeting } from '../pages';
import { MyProvider } from './context';

function App() {
    return (
        <MyProvider>
            <div className="App">
                <Meeting />
            </div>
        </MyProvider>
    );
}

export default App;
