/* eslint-disable */
/* tslint:disable */
import PropTypes from 'prop-types';
import React from 'react';
const Link = ({size, ...props}) => (
  <svg viewBox="0 0 17 17" fill="currentColor" width={ size || "17" } height={ size || "17" } {...props}>
    <path d="M10.6179 12.3958H11.6875C13.8267 12.3958 15.5833 10.6462 15.5833 8.49996 15.5833 6.36079 13.8338 4.60413 11.6875 4.60413H10.6179M6.37508 4.60413H5.31258C3.16633 4.60413 1.41675 6.35371 1.41675 8.49996 1.41675 10.6391 3.16633 12.3958 5.31258 12.3958H6.37508M5.66675 8.5H11.3334"
      stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
Link.displayName = 'Link';
Link.propTypes = {
  size: PropTypes.string
}
export default Link;
/* tslint:enable */
/* eslint-enable */
