import { SpeechItem } from './index';
import Player from 'video.js/dist/types/player';
import './speechTimeline.scss';
import { randomColors } from 'constants/randomColors';
import { splitFullName } from 'utils/splitFullName';
import { Dispatch, SetStateAction } from 'react';

interface Speech {
    firstName: string;
    lastName: string;
    timeSlots: { start: number; end: number }[];
    speaker: string;
}

interface Props {
    meetingDuration: number | undefined;
    speakersList: Speech[];
    isVideo: boolean;
    setPlayed: Dispatch<SetStateAction<number>>;
    setIsVideoPlaying: Dispatch<SetStateAction<boolean>>;
    wavesurfer: any;
    isVideoReady: boolean;
    isAudioReady: boolean;
    setIsAudioPlaying: Dispatch<SetStateAction<boolean>>;
}

const mapSpeechData = (speakersList: any) => {
    const speechTimeArr: any = [];

    speakersList.forEach((item: any) => {
        const { firstName, lastName } = splitFullName(item.speaker);
        if (
            speechTimeArr.some(
                (speech: any) => speech.firstName === firstName && speech.lastName === lastName,
            )
        ) {
            const index = speechTimeArr.findIndex(
                (speech: any) => speech.firstName === firstName && speech.lastName === lastName,
            );

            speechTimeArr[index].timeSlots.push({
                start: item.timeSlots.start,
                end: item.timeSlots.end,
            });
        } else {
            speechTimeArr.push({ firstName, lastName, ...item, timeSlots: [item.timeSlots] });
        }
    });

    return speechTimeArr;
};

export const SpeechTimeline = ({
    meetingDuration,
    speakersList = [],
    isVideo,
    setPlayed,
    setIsVideoPlaying,
    wavesurfer,
    isVideoReady,
    isAudioReady,
    setIsAudioPlaying,
}: Props) => {
    const speeches: Speech[] = mapSpeechData(speakersList);

    const getDistinctUsers = () => {
        const distinctUsersArr = [...new Set(speeches.map((t) => t.speaker))];
        return distinctUsersArr;
    };

    const getUserColor = (user: any) => {
        const index = getDistinctUsers().findIndex((u) => u === user);
        return randomColors[index];
    };

    return (
        <div className="speech-timeline-container">
            {speeches.map((speech: Speech, i) => {
                return (
                    <SpeechItem
                        avatarBackgroundColor={getUserColor(speech.speaker)}
                        speech={speech}
                        duration={meetingDuration}
                        isVideo={isVideo}
                        setPlayed={setPlayed}
                        setIsVideoPlaying={setIsVideoPlaying}
                        wavesurfer={wavesurfer}
                        isVideoReady={isVideoReady}
                        isAudioReady={isAudioReady}
                        setIsAudioPlaying={setIsAudioPlaying}
                    />
                );
            })}
        </div>
    );
};
