import { Expand } from 'assets/iconComponents';

interface ExpandControllerProps {
    iconsClassName: string;
    handleExpand: () => void;
}

export const ExpandController = ({ iconsClassName, handleExpand }: ExpandControllerProps) => (
    <Expand
        onClick={handleExpand}
        className={`cursor-pointer ${iconsClassName} fill-none`}
        size={'18'}
    />
);
