import { createContext, useReducer } from 'react';
import { v4 as uuidv4 } from 'uuid';

type State = {
    meetingCurrentTime: number;
    jumpToTime?: number;
    conversationId?: any;
};

type Action = {
    type: 'UPDATE_TIME' | 'JUMP_TO_TIME' | 'SET_CONVERSATION_ID';
    payload: number ;
};

const initialState: State = {
    meetingCurrentTime: 0,
    jumpToTime: 0,
    conversationId : uuidv4()
};

const reducer = (state: State, action: Action): State => {
    switch (action.type) {
        case 'UPDATE_TIME':
            return {
                ...state,
                meetingCurrentTime: action.payload,
            };
        case 'JUMP_TO_TIME':
            return {
                ...state,
                jumpToTime: action.payload,
            };
        case 'SET_CONVERSATION_ID':
            return {
                ...state,
                conversationId: action.payload,
            };
        default:
            return state;
    }
};

export const MyContext = createContext({
    state: initialState,
    updateTime: (time: number) => {},
    jumpToTimeFrame: (time: number) => {},
    updateConversationId: (conversationId: any) => {},
});

export const MyProvider = ({ children }: { children: React.ReactNode }) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    const updateTime = (time: number) => {
        dispatch({ type: 'UPDATE_TIME', payload: time });
    };

    const jumpToTimeFrame = (time: number) => {
        dispatch({ type: 'JUMP_TO_TIME', payload: time });
    };

    const updateConversationId = (conversationId: any) => {
        dispatch({ type: 'SET_CONVERSATION_ID', payload: conversationId });
    };


    return (
        <MyContext.Provider value={{ state, updateTime, jumpToTimeFrame, updateConversationId }}>
            {children}
        </MyContext.Provider>
    );
};
