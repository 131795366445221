const randomColor = require('randomcolor');

const generateStaticColorsArray = (count : number, seed : number) => {
  // Generate the array of random colors with the seed option
  const colorsArray = randomColor({ count, seed });

  return colorsArray;
};

// Example: Generate an array of 5 colors with seed 123
export const randomColors = generateStaticColorsArray(200, 123);
