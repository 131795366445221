import './loadingAnimation.scss';

export const LoadingAnimation = ({ className }: { className: string }) => (
    <div className={`spinner h-27 w-27 inline-block p-5 rounded-base ${className}`}>
        <div className="bar1 animate-fade w-[7%] h-[18%] bg-primary absolute left-[50%] top-[43%] opacity-0   rounded-[4rem] "></div>
        <div className="bar2 animate-fade w-[7%] h-[18%] bg-primary absolute left-[50%] top-[43%] opacity-0 rounded-[4rem] "></div>
        <div className="bar3 animate-fade w-[7%] h-[18%] bg-primary absolute left-[50%] top-[43%] opacity-0   rounded-[4rem] "></div>
        <div className="bar4 animate-fade w-[7%] h-[18%] bg-primary absolute left-[50%] top-[43%] opacity-0   rounded-[4rem] "></div>
        <div className="bar5 animate-fade w-[7%] h-[18%] bg-primary absolute left-[50%] top-[43%] opacity-0   rounded-[4rem] "></div>
        <div className="bar6 animate-fade w-[7%] h-[18%] bg-primary absolute left-[50%] top-[43%] opacity-0   rounded-[4rem] "></div>
        <div className="bar7 animate-fade w-[7%] h-[18%] bg-primary absolute left-[50%] top-[43%] opacity-0   rounded-[4rem] "></div>
        <div className="bar8 animate-fade w-[7%] h-[18%] bg-primary absolute left-[50%] top-[43%] opacity-0   rounded-[4rem] "></div>
    </div>
);
