/* eslint-disable */
/* tslint:disable */
import PropTypes from 'prop-types';
import React from 'react';
const Maximize = ({size, ...props}) => (
  <svg viewBox="0 0 23 24" fill="currentColor" width={ size || "23" } height={ size || "24" } {...props}>
    <path d="M16.1382 3.1167H19.9354V6.91389M14.2395 8.81249L19.9353 3.1167M6.6451 20.2041H2.8479V16.4069M8.54369 14.5083L2.8479 20.2041M13.309 2.35723C12.692 2.23382 12.0464 2.16736 11.3914 2.16736 6.1513 2.16736 1.89844 6.42022 1.89844 11.6603 1.89844 12.3154 1.9649 12.9514 2.08831 13.5589M9.47388 20.9636C10.0909 21.087 10.7365 21.1534 11.3915 21.1534 16.6316 21.1534 20.8845 16.9006 20.8845 11.6604 20.8845 11.0149 20.818 10.3789 20.6946 9.76184"
      stroke="currentColor" strokeWidth="1.117" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
Maximize.displayName = 'Maximize';
Maximize.propTypes = {
  size: PropTypes.string
}
export default Maximize;
/* tslint:enable */
/* eslint-enable */
