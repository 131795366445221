import { Dispatch, SetStateAction, useMemo, useState } from 'react';
import { Summary } from '../summary';
import { Transcripts } from '../transcripts';
import './meetingInfo.scss';
import { useTranslation } from 'react-i18next';
import { Articles } from 'components/articles';

interface MeetingInfoProps {
    meetingInfo: any;
    summaryData: string;
    transcripts: any;
    transcriptsText: string;
    articles: [];
    url: string;
    duration: number;
    setPlayed: Dispatch<SetStateAction<number>>;
    setIsVideoPlaying: Dispatch<SetStateAction<boolean>>;
    wavesurfer: any;
    isVideoReady: boolean;
    isAudioReady: boolean;
    played: number;
    currentTime: number;
    setIsAudioPlaying: Dispatch<SetStateAction<boolean>>;
    setCurrentTime: Dispatch<SetStateAction<number>>;
}

const Empty = () => <></>;

export const MeetingInfo = ({
    meetingInfo,
    summaryData,
    transcripts,
    transcriptsText,
    articles,
    url,
    duration,
    setPlayed,
    setIsVideoPlaying,
    wavesurfer,
    isVideoReady,
    isAudioReady,
    played,
    setIsAudioPlaying,
    currentTime,
    setCurrentTime,
}: MeetingInfoProps) => {
    const { t } = useTranslation();

    // component layout management
    const [activeTab, setActiveTab] = useState(0);
    const [messageContent, setMessageContent] = useState('');
    const [messages, setMessages] = useState([]);

    // data store
    const tabs = useMemo(() => {
        const initialTabs = [];
        if (transcripts.length || transcriptsText)
            initialTabs.push({ label: t('transcript'), component: Transcripts });
        if (summaryData) initialTabs.push({ label: t('meetingSummary'), component: Summary });
        if (articles.length) initialTabs.push({ label: t('articles'), component: Articles });
        return initialTabs;
    }, [transcripts, transcriptsText, summaryData, articles, t]);

    const dataManager = useMemo(
        () => [
            {
                transcripts,
                transcriptsText,
                url,
                duration,
                setPlayed,
                setIsVideoPlaying,
                wavesurfer,
                isVideoReady,
                isAudioReady,
                played,
                setIsAudioPlaying,
                currentTime,
                setCurrentTime,
            },
            summaryData,
            articles,
        ],
        [
            transcripts,
            summaryData,
            transcriptsText,
            articles,
            url,
            duration,
            setPlayed,
            setIsVideoPlaying,
            wavesurfer,
            isVideoReady,
            isAudioReady,
            played,
            setIsAudioPlaying,
            currentTime,
            setCurrentTime,
        ],
    );

    // data management
    const onQuestionClick = (message: string) => {
        setMessageContent(message);
        setActiveTab(2);
    };
    const renderActiveTabComponent = () => {
        const ActiveComponent: any = tabs[activeTab]?.component || Empty;
        const data = dataManager[activeTab] || [];
        const SUMMARY_TAB_INDEX = 1;
        const TRANSCRIPTS_TAB_INDEX = 2;
        const ARTICLES_TAB_INDEX = 3;
        const transcriptProps =
            activeTab === TRANSCRIPTS_TAB_INDEX
                ? {
                      onQuestionClick,
                      transcriptsText,
                      url,
                      duration,
                      setPlayed,
                      setIsVideoPlaying,
                      wavesurfer,
                      played,
                      setIsAudioPlaying,
                      currentTime,
                      setCurrentTime,
                  }
                : [];
        const summaryProps =
            activeTab === SUMMARY_TAB_INDEX
                ? { messageContent, setMessages, messages, setMessageContent, meetingInfo }
                : {};
        const articlesProps =
            activeTab === ARTICLES_TAB_INDEX
                ? { messageContent, setMessages, messages, setMessageContent, meetingInfo }
                : {};
        return (
            <ActiveComponent
                {...transcriptProps}
                {...summaryProps}
                {...articlesProps}
                data={data}
            />
        );
    };

    const onTabChange = (index: number) => {
        setActiveTab(index);
    };

    return (
        <div className="meeting-info">
            <ul className="tabs-container ">
                {tabs.map((tab, i) => (
                    <li
                        className={`tab-item bg-light rounded-9 ${
                            activeTab === i ? 'active' : ''
                        } `}
                        key={tab.label}
                        onClick={() => onTabChange(i)}
                    >
                        {tab.label}
                    </li>
                ))}
            </ul>
            <div className="tab-content">{renderActiveTabComponent()}</div>
        </div>
    );
};
