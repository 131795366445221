/* eslint-disable */
/* tslint:disable */
import PropTypes from 'prop-types';
import React from 'react';
const Send = ({size, ...props}) => (
  <svg viewBox="0 0 16 16" fill="currentColor" width={ size || "16" } height={ size || "16" } {...props}>
    <path d="M4.93337 4.21344L10.5934 2.32677C13.1334 1.4801 14.5134 2.86677 13.6734 5.40677L11.7867 11.0668C10.52 14.8734 8.44003 14.8734 7.17337 11.0668L6.61337 9.38677 4.93337 8.82677C1.1267 7.5601 1.1267 5.48677 4.93337 4.21344zM6.73999 9.10012L9.12666 6.70679"
      stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
Send.displayName = 'Send';
Send.propTypes = {
  size: PropTypes.string
}
export default Send;
/* tslint:enable */
/* eslint-enable */
