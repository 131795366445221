import axios from '../../app/apiInstance';

export const fetchMeetingDetails = async (token: string) => {
    const { data } = await axios.get(`/preview`, {
        headers: {
            'url-token': token,
        },
    });
    return data;
};
